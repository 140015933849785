<template>
    <div class="eligibility-status-container">
        <h1>Eligibility Status</h1>

        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group
                id="input-group-email"
                label="Work Email:"
                label-for="input-email"
            >
                <b-form-input
                    id="input-email"
                    v-model="form.email"
                    type="email"
                    placeholder="Enter team members work email."
                    required
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-min-salary"
                label="Minimum Salary:"
                label-for="input-min-salary"
            >
                <b-form-input
                    id="input-min-salary"
                    v-model="form.salary_min"
                    type="number"
                    placeholder="Enter minimum salary range for this role."
                    required
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-max-salary"
                label="Maximum Salary:"
                label-for="input-max-salary"
            >
                <b-form-input
                    id="input-max-salary"
                    v-model="form.salary_max"
                    type="number"
                    placeholder="Enter maximum salary range for this role."
                    required
                ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary">Search</b-button>
        </b-form>

        <br/>

        <div class='alert alert-danger' v-if="eligibility && eligibility.error">
            {{ eligibility.error }}
        </div>
        <b-card v-else-if="eligibility && eligibility.workEmail">
            <b-table-simple small stacked>
            <colgroup><col><col></colgroup>
            <colgroup><col><col><col></colgroup>
            <colgroup><col><col></colgroup>
            <b-tbody>
                <b-tr>
                    <b-th stacked-heading="Work Email" class="text-left"><code>{{ eligibility['workEmail'] }}</code></b-th>
                    <b-td stacked-heading="Team Member" class="text-left">{{  eligibility['preferredName'] }}</b-td>
                    <b-td stacked-heading="Supervisor">{{ eligibility['supervisorName'] }}</b-td>
                    <b-td stacked-heading="Compensation In Range">{{  eligibility['compInLookUpRange'] }}</b-td>
                    <b-td stacked-heading="Performance Rating">{{ eligibility['performance'] }}</b-td>
                    <b-td stacked-heading="Job Grade">{{ eligibility['jobGrade'] }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        </b-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: { email: '', salary_min: null, salary_max: null },
            show: false,
            eligibility: null,
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            let options = { 
                method: 'POST',
                headers: {
                    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(this.form)
            }
            fetch('/talent', options)
                .then(response => response.json())
                .then(data => { this.eligibility = data })
                .catch((error) => { console.error(error) })
        },
        onReset(event) {
            event.preventDefault();
            this.form.email = null
            this.form.salary_min = null
            this.form.salary_max = null
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => { this.show = true })
        }
    }
}
</script>